import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import BookmarkIcon from '@mui/icons-material/Bookmark';

interface BookmarkAvatarProps {
    bookmark: {
        url: string;
        monitoringStatus?: string;
        imageUrl?: string;
    };
    open: () => void;
}

const getStatusColor = (v: string | undefined) => {
    switch (v) {
        case "online": return "#04a604";
        case "offline": return "#d93838";
        default: return "#cccccc";
    }
};

const autoCorrectURL = (url: string) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'https://' + url; // Default to HTTPS for security
    }
    return url;
}

const BookmarkAvatar: React.FC<BookmarkAvatarProps> = ({ bookmark, open }) => {
    const [faviconUrl, setFaviconUrl] = useState<string | null>(null);

    useEffect(() => {


        const fetchFavicon = async () => {
            try {
                const domain = new URL(autoCorrectURL(bookmark.url)).hostname;
                const faviconPath = `https://${domain}/favicon.ico`;
                const response = await fetch(faviconPath, { method: 'HEAD' });
                if (response.ok) {
                    setFaviconUrl(faviconPath);
                }
            } catch (err) {
                console.warn('Error fetching favicon:', err);
            }
        };

        if (!bookmark.imageUrl) {
            fetchFavicon();
        }
    }, [bookmark.url, bookmark.imageUrl]);

    const avatarContent = bookmark.imageUrl || faviconUrl ? (
        <img src={bookmark.imageUrl || faviconUrl || ""} alt="Bookmark Icon" />
    ) : (
        <BookmarkIcon />
    );

    return (
        <Avatar
            className="logo"
            style={{
                borderColor: getStatusColor(bookmark.monitoringStatus?.toLowerCase()),
            }}
            onClick={open}
        >
            {avatarContent}
        </Avatar>
    );
};

export default BookmarkAvatar;
