


const View = () => {


    return (
        <div>
            SystemNetworkView
        </div>
    )
}

export default View;