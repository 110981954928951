import {useState, useMemo, ReactNode, useRef, useEffect} from "react";
import WidgetContainer, {ContainerSize} from "../widget/container/WidgetContainer";
import "./style/single-view-list.scss";


interface SingleViewListProps {
    size: ContainerSize;
    onSelect: (selection: string) => void;
    list: string[];
    selectedItem?: string;
    highlightSelection?: boolean;
    initialPageSize?: number;
    pageSizeOptions?: number[];
    emptyListView?: ReactNode;
}

const SingleViewList: React.FC<SingleViewListProps> = ({
    size,
    onSelect,
    list,
    selectedItem,
    highlightSelection = false,
    initialPageSize = 10,
    pageSizeOptions = [5, 10, 20, 50],
    emptyListView = <h2>NO SEARCH RESULTS</h2>
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const totalPages = Math.ceil(list.length / pageSize);
    const paginatedDomains = useMemo(() => {
        const startIdx = (currentPage - 1) * pageSize;
        return list.slice(startIdx, startIdx + pageSize);
    }, [list, currentPage, pageSize]);
    const handlePageChange = (newPage: number) => {
        setCurrentPage(Math.max(1, Math.min(newPage, totalPages)));
    };
    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
        setCurrentPage(1);  // Reset to first page on page size change
    };
    const tableContainerRef = useRef<HTMLDivElement>(null);


    const getSizeList = () => {
        // Remove duplicates, sort from smallest to largest
        return Array.from(new Set([...pageSizeOptions])).sort((a, b) => a - b);
    };




    return (
        <WidgetContainer id="domain-results" size={size}>
            {list.length === 0 ?  (emptyListView) : (
                <>
                    <div className="table-container" ref={tableContainerRef} >
                        <table>
                            <colgroup>
                                <col className="field-domain" />
                            </colgroup>
                            <thead>
                            <tr>
                                <th>Domain</th>
                            </tr>
                            </thead>
                            <tbody>
                            {paginatedDomains.map((domain) => (
                                <tr
                                    key={domain}
                                    className={highlightSelection && selectedItem === domain ? "selected" : undefined}
                                    onClick={() => onSelect(domain)}
                                >
                                    <td>{domain}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination-controls">
                        <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>First</button>
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                        <span>{currentPage} of {totalPages}</span>
                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                        <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>Last</button>
                        {pageSizeOptions.length > 1 && (
                            <label>
                                <select value={pageSize} onChange={handlePageSizeChange}>
                                    {getSizeList().map((size) => (
                                        <option key={size} value={size}>
                                            {size}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        )}
                    </div>
                </>
            )}
        </WidgetContainer>
    );



}

export default SingleViewList;