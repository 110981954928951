// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invite-accepted {
  padding: 20px;
  max-width: 400px;
  margin: auto;
  text-align: center;
}
.invite-accepted h2 {
  margin-bottom: 20px;
}
.invite-accepted .MuiTextField-root {
  margin-bottom: 15px;
}
.invite-accepted .MuiButton-root {
  margin-top: 10px;
}
.invite-accepted p {
  margin-top: 10px;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/styles/InviteAcceptedProps.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,gBAAA;EACA,UAAA;AAFJ","sourcesContent":[".invite-accepted {\r\n  padding: 20px;\r\n  max-width: 400px;\r\n  margin: auto;\r\n  text-align: center;\r\n\r\n  h2 {\r\n    margin-bottom: 20px;\r\n  }\r\n\r\n  .MuiTextField-root {\r\n    margin-bottom: 15px;\r\n  }\r\n\r\n  .MuiButton-root {\r\n    margin-top: 10px;\r\n  }\r\n\r\n  p {\r\n    margin-top: 10px;\r\n    color: red;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
