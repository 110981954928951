import React, { useState, useEffect } from "react";
import {Container, Typography, TextField, Box, Button, Card} from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { useApi } from "../context/ApiContext";
import FolderTree from "../components/FolderTree";
import AddBookmarkModal from "../components/AddBookmarkModal";
import "../styles/BookmarkPage.scss";
import BookmarkItem from "../components/BookmarkItem";
import {useBookmark} from "../context/BookmarkContext";

interface Bookmark {
    id: string;
    title: string;
    url: string;
    imageUrl: string | null;
    folder: string;
    monitoringEnabled: boolean;
    monitoringUrl: string;
    monitoringStatus: string;
    updatedAt: string;
}

const BookmarksPage: React.FC = () => {
    const api = useApi();
    const bookmark = useBookmark();
    const [openBookmarkCreator, setBookmarkCreator] = useState<boolean>(false);
    const [editBookmark, setBookmarkEdit] = useState<Bookmark | undefined>(undefined);
    const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
    const [currentFolder, setCurrentFolder] = useState<string>("/user");
    const [searchQuery, setSearchQuery] = useState<string>("");

    const updateBookmarks = () => {
        bookmark.pull().then(
            (bk) => {
                setBookmarks(bk);
            }
        ).catch(
            (err) => console.error("Error fetching bookmarks:", err)
        );
    }

    useEffect(() => {
        bookmark.get().then(
            (bk) => {
                setBookmarks(bk);
            }
        ).catch(
            (err) => console.error("Error fetching bookmarks:", err)
        );
    }, []);

    const getFolderList = () => {
        const folders = new Set<string>();
        bookmarks.forEach((bookmark) => {
            if (bookmark.folder !== currentFolder) {
                folders.add(bookmark.folder);
            }
        });
        return Array.from(folders);
    };

    const handleFolderClick = (folderPath: string) => {
        setCurrentFolder(folderPath);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const filteredBookmarks = bookmarks.filter((bookmark) =>
        bookmark.folder.toLowerCase().startsWith(currentFolder.toString().toLowerCase()) &&
        (bookmark.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        bookmark.url.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const uniqueFolders = (bookmarks: Bookmark[]) => {
        let folders: string[] = []
        bookmarks.forEach((bk) => {
            if(!folders.includes(bk.folder)) {
                folders.push(bk.folder);
            }
        })
        return [ ...folders.sort().map((folder) => ({folder: folder})) ];
    }


    return (
        <Container className={"bookmarkPage"}>
            <Box className={"bookmarkPage-content"}>
                <Box className={"left"}>
                    <Box className={"folder-header"}>
                        <Typography variant="h6">Folders</Typography>
                        <Button variant={"contained"} onClick={updateBookmarks} ><CachedIcon/></Button>
                    </Box>
                    <FolderTree
                        bookmarks={uniqueFolders(bookmarks)}
                        onFolderSelect={handleFolderClick}
                    />
                </Box>
                <Box  className={"right"}>
                    <Box className={"search-wrapper"} mb={2}>
                        <TextField
                            label="Search Bookmarks"
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            fullWidth
                        />
                        <Button onClick={() => {setBookmarkCreator(true);}} variant="contained" color="primary">
                            <BookmarkAddIcon/>
                        </Button>
                    </Box>
                    <Card className={"scroller-wrapper"}>
                        <Box className={"scroller"} flexGrow={1} p={2} display={"flex"} flexWrap={"wrap"} gap={3}>
                            {filteredBookmarks.map((bookmark) => (
                                <BookmarkItem
                                    key={bookmark.id}
                                    bookmark={bookmark}
                                    onEditClick={() => {setBookmarkEdit(bookmark); setBookmarkCreator(true);}}
                                />
                            ))}
                        </Box>
                    </Card>
                </Box>
            </Box>
            <AddBookmarkModal
                bookmark={editBookmark}
                open={openBookmarkCreator}
                onClose={() => {setBookmarkCreator(false); setBookmarkEdit(undefined);}}
                existingFolders={getFolderList()}
                onCreate={() => {
                    setBookmarkCreator(false);
                    setBookmarkEdit(undefined);
                    updateBookmarks();
                }}
            />
        </Container>
    );
};

export default BookmarksPage;
export type {Bookmark};
