import React, {useState, useEffect, useCallback, useRef} from "react";
import "../styles/InactivityMonitor.scss";
import {useApi} from "./ApiContext";
import {useUser} from "./UserContext";

interface InactivityMonitorProps {
    inactiveTimeLimit?: number; // Time before showing warning (ms)
    warningTimeLimit?: number;  // Time before auto-logout after warning (ms)
}

const InactivityMonitor: React.FC<InactivityMonitorProps> = ({
     inactiveTimeLimit = 9.5 * 60000, // 9.5 minutes
     warningTimeLimit = 30000, // 30 seconds
 }) => {
    const user = useUser();
    const [isWarningVisible, setIsWarningVisible] = useState(false);
    const [timeLeft, setTimeLeft] = useState(warningTimeLimit / 1000);
    const lastActivity = useRef<number>(Date.now());
    let inactivityTimer: NodeJS.Timeout;
    let countdownTimer: NodeJS.Timeout;

    // Resets inactivity timer
    const resetInactivityTimer = useCallback(() => {
        lastActivity.current = Date.now();
        clearTimeout(inactivityTimer);
        clearTimeout(countdownTimer);
        setIsWarningVisible(false);
        setTimeLeft(warningTimeLimit / 1000);

        inactivityTimer = setTimeout(() => {
            setIsWarningVisible(true);
            startCountdown();
        }, inactiveTimeLimit);
    }, []);

    useEffect(() => {
        const sessionManagement = () => {
            if (lastActivity.current > Date.now() - 3 * 60 * 1000) { // Was activity with-in last 3 minutes
                user.keepAlive();
            }
        }
        let interval: NodeJS.Timeout | null = null;
        if (user.user && !isWarningVisible) { interval = setInterval(sessionManagement, 2 * 60 * 1000); } // Every 2 minutes
        return () => { if (interval) { clearInterval(interval); } };
    }, [user, isWarningVisible]);

    // Starts countdown before auto logout
    const startCountdown = () => {
        let countdown = warningTimeLimit / 1000;
        setTimeLeft(countdown);

        countdownTimer = setInterval(() => {
            countdown -= 1;
            setTimeLeft(countdown);

            if (countdown <= 0) {
                clearInterval(countdownTimer);
                setIsWarningVisible(false);
                user.logout();
            }
        }, 1000);
    };

    // Effect to add activity listeners
    useEffect(() => {
        if(user.user) {
            const handleActivity = () => resetInactivityTimer();
            window.addEventListener("mousemove", handleActivity);
            window.addEventListener("keydown", handleActivity);
            window.addEventListener("click", handleActivity);

            resetInactivityTimer(); // Initialize the timer

            return () => {
                window.removeEventListener("mousemove", handleActivity);
                window.removeEventListener("keydown", handleActivity);
                window.removeEventListener("click", handleActivity);
                clearTimeout(inactivityTimer);
                clearTimeout(countdownTimer);
            };
        }else {
            setIsWarningVisible(false);
        }
    }, [resetInactivityTimer, user]);

    return isWarningVisible ? (
        <div className="inactivity-overlay">
            <div className="inactivity-modal">
                <h2>Are you still there?</h2>
                <p>You will be logged out in <span className="countdown">{timeLeft}</span> seconds.</p>
                <button onClick={resetInactivityTimer} className="inactivity-button">
                    I'm Here
                </button>
            </div>
        </div>
    ) : null;
};

export default InactivityMonitor;
