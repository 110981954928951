import React from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {useUser} from "../context/UserContext";

interface NavbarProps {
    title: string;
}

const Navbar: React.FC<NavbarProps> = ({ title }) => {
    const navigator = useNavigate();
    const user = useUser();
    return (
        <AppBar position="sticky">
            <Toolbar sx={{justifyContent: "center"}}>
                <Typography
                    variant="h6"
                    style={{ width: "max-content", flexGrow: 1, justifyContent: "start", gap: "10px", display: "flex", cursor: "pointer" }}
                    onClick={() => { navigator("/");}}
                >
                    <img src={"/logo1024.png"} style={{height: "32px"}} className={"logo"} alt={"Application Logo"}/>
                    Digital Bookmarks </Typography>
                {user.user?.username && (
                    <>
                        {/* <Button color="inherit" component={Link} to="/dashboard">Home</Button> */}
                        {user.user?.roles?.includes("ADMIN") && (
                            <Button color="inherit" component={Link} to="/management">Management</Button>
                        )}
                        <Button color="inherit" onClick={() => {
                            user.logout();
                            navigator("/");
                        }}>Logout</Button>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
