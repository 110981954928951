import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";

interface DeleteBookmarkModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    bookmarkTitle: string;
}

const DeleteBookmarkModal: React.FC<DeleteBookmarkModalProps> = ({ open, onClose, onConfirm, bookmarkTitle }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
                <Typography>Are you sure you want to delete the bookmark "{bookmarkTitle}"?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onConfirm} variant="contained" color="error">Delete</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteBookmarkModal;
