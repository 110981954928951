import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/default.scss";
import App from './App';
import reportWebVitals from './reportWebVitals';
import RouterManagerProvider from "./managers/RouterManager";
import MemoryManagerProvider from "./managers/MemoryManager";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterManagerProvider>
            <MemoryManagerProvider>
                <App />
            </MemoryManagerProvider>
        </RouterManagerProvider>
    </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
