import React, { createContext, useContext, useCallback, useState, ReactNode } from "react";
import { Views } from "./RouterManager";

// Define custom types for each view's memory
interface DashboardMemory {
    lastVisited: Date;
    widgets: string[];
}

interface SysHealthMemory {
    cpuUsage: number;
    memoryUsage: number;
}

interface SysNetworkMemory {
    networkStatus: string;
    lastPing: Date;
}

// Define a union type that maps each view to its memory type
type ViewMemory = {
    [Views.Dashboard]: DashboardMemory;
    [Views.SYS_Health]: SysHealthMemory;
    [Views.SYS_Network]: SysNetworkMemory;
    [key: string]: any; // Fallback for views without specific memory type
};

// Define types for the context
interface ManagerContextProps {
    addMemory: <T extends Views>(view: T, memory: ViewMemory[T]) => void;
    getMemory: <T extends Views>(view: T) => ViewMemory[T] | undefined;
}

interface ManagerProviderProps {
    children: ReactNode;
}

// Initialize context with an empty object cast to the interface
const ManagerContext = createContext<ManagerContextProps | undefined>(undefined);

// Custom hook for accessing the memory manager context
const useMemoryManager = (): ManagerContextProps => {
    const context = useContext(ManagerContext);
    if (!context) {
        throw new Error("useMemoryManager must be used within a MemoryManagerProvider");
    }
    return context;
};

// Provider component
const MemoryManagerProvider: React.FC<ManagerProviderProps> = ({ children }) => {
    const [memory, setMemory] = useState<Partial<ViewMemory>>({});

    const addMemory = useCallback(<T extends Views>(view: T, memoryData: ViewMemory[T]) => {
        setMemory(prevMemory => ({ ...prevMemory, [view]: memoryData }));
    }, []);

    const getMemory = useCallback(<T extends Views>(view: T) => memory[view] as ViewMemory[T], [memory]);

    // Memoize the context to avoid unnecessary renders
    const contextValue = React.useMemo(() => ({
        addMemory,
        getMemory,
    }), [addMemory, getMemory]);

    return (
        <ManagerContext.Provider value={contextValue}>
            {children}
        </ManagerContext.Provider>
    );
};

export default MemoryManagerProvider;
export { useMemoryManager, Views };
