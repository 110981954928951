import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import UserProvider from "./context/UserContext";
import {ApiProvider} from "./context/ApiContext";
import InactivityMonitor from "./context/InactivityMonitor";
import BookmarkProvider from "./context/BookmarkContext";
import { BrowserRouter as Router} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ApiProvider>
          <Router>
              <UserProvider>
                  <InactivityMonitor/>
                  <BookmarkProvider>
                      <App />
                  </BookmarkProvider>
              </UserProvider>
          </Router>
      </ApiProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
