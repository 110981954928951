import FolderIcon from "@mui/icons-material/Folder";
import {Card, Typography} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React from "react";

interface FolderProps {
    name: string;
    selected: boolean;
    subFolders?: boolean;
    isOpen?: boolean;
    onFolderClick?: () => void;
    onFolderDoubleClick?: () => void;
}

const Folder:React.FC<FolderProps> = ({name, selected, subFolders = false, isOpen = false, onFolderClick, onFolderDoubleClick}) => {
    return (
        <Card className={`folder${selected ? " selected" : ""}`}
              onClick={onFolderClick}
              onDoubleClick={onFolderDoubleClick}>
            <FolderIcon />
            <Typography flex={1}>{name}</Typography>
            {subFolders && (isOpen ?
                <ExpandLess onClick={onFolderDoubleClick} /> :
                <ExpandMore onClick={onFolderDoubleClick}/>)}
        </Card>
    )
}

export default Folder;