import {useApi} from "../context/ApiContext";
import React, {useEffect, useState} from "react";
import {Button, TextField} from "@mui/material";
import "../styles/InviteAcceptedProps.scss"
import { useParams } from "react-router-dom";

interface InviteAcceptedProps {

}
const InviteAccepted: React.FC<InviteAcceptedProps> = ({}) => {
    const api = useApi();
    const { id } = useParams(); // Get the 'id' path param
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [pin, setPin] = useState("");

    useEffect(() => {
        handleRequestVerification();
    }, []);

    const handleRequestVerification = async () => {
        api.post("/account/invite/code", { requestId: id });
    }
    const handlePinVerification = async () => {
        await api.post("/account/invite/confirm",  { requestId: id, pin});
    }

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            setMessage("Passwords do not match");
            return;
        }
        try {
            await api.post("/account/invite/register", { requestId: id, pin, username, password});
            setMessage("Account created successfully!");
        } catch (error) {
            setMessage("Error confirming invite");
        }
    };

    return (
        <div className="invite-accepted">
            <h2>Accept Invitation</h2>
            <TextField label="pin" value={pin} onChange={(e) => setPin(e.target.value)} fullWidth margin="normal" />
            <TextField label="Username" value={username} onChange={(e) => setUsername(e.target.value)} fullWidth margin="normal" />
            <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} fullWidth margin="normal" />
            <TextField label="Confirm Password" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} fullWidth margin="normal" />
            <Button onClick={handleSubmit} color="primary">Submit</Button>
            {message && <p>{message}</p>}
        </div>
    );
};

export default InviteAccepted;