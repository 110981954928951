import React, {useState} from 'react';
import NavBar from "./components/navigator/NavBar";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardView from "./views/DashboardView";
import SystemMaintenanceView from "./views/System/maintenance/View";
import SystemServiceView from "./views/System/service/View";
import SystemHealthView from "./views/System/health/View";
import SystemNetworkView from "./views/System/network/View";
import RestrictionDomainView from "./views/Restrictions/domain/View";
import RestrictionUserView from "./views/Restrictions/user/View";
import RestrictionIPView from "./views/Restrictions/ip/View";
import UserAccountView from "./views/User/account/View";
import UserTeamView from "./views/User/team/View";
import UserTemplateView from "./views/User/template/View";
import UserReviewView from "./views/User/review/View";
import {useRouterManager, Views} from "./managers/RouterManager";

const App = () => {
    const routerManager =  useRouterManager();

    return (
        <div className="App">
            <NavBar/>
            <main>
                <Router>
                    <Routes location={`/${routerManager.getCurrentView()}`}>
                        <Route path={`/${Views.Dashboard}`} element={<DashboardView />} />
                        <Route path={`/${Views.SYS_Health}`} element={<SystemHealthView />} />
                        <Route path={`/${Views.SYS_Network}`} element={<SystemNetworkView />} />
                        <Route path={`/${Views.SYS_Services}`} element={<SystemServiceView />} />
                        <Route path={`/${Views.SYS_Maintenance}`} element={<SystemMaintenanceView />} />
                        <Route path={`/${Views.RMGT_Domain}`} element={<RestrictionDomainView />} />
                        <Route path={`/${Views.RMGT_User_Bans}`} element={<RestrictionUserView />} />
                        <Route path={`/${Views.RMGT_IP_Bans}`} element={<RestrictionIPView />} />
                        <Route path={`/${Views.MGT_Account}`} element={<UserAccountView />} />
                        <Route path={`/${Views.MGT_Team}`} element={<UserTeamView />} />
                        <Route path={`/${Views.MGT_Template}`} element={<UserTemplateView />} />
                        <Route path={`/${Views.MGT_Review}`} element={<UserReviewView />} />
                    </Routes>
                </Router>
            </main>
        </div>
    );
}

export default App;
