// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#AccountSearchModal {
  position: absolute;
  width: min-content;
  height: min-content;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/views/User/account/style/search-account-modal.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,SAAA;EACA,QAAA;EACA,4CAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF","sourcesContent":["#AccountSearchModal {\r\n  position: absolute;\r\n  width: min-content;\r\n  height: min-content;\r\n  left: 50%;\r\n  top: 50%;\r\n  transform: translateX(-50%) translateY(-50%);\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
