

const DashboardView = () => {
    return (
        <div>
            DashboardView
        </div>
    )
}

export default DashboardView;