import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import Dashboard from "./pages/Dashboard";
import BookmarksPage from "./pages/BookmarksPage";
import {useUser} from "./context/UserContext";
import UserManagement from "./pages/UserManagementPage";
import InviteAccepted from "./pages/InviteResponsePage";

const App: React.FC = () => {
    const user = useUser();
  return (
      <>
        <Navbar title={"Develop"} />
        <Routes>
            {user.user && (
                <>
                    <Route path="/management" element={<UserManagement />}/>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/" element={<BookmarksPage />} />
                    <Route path="*" element={<NotFound />} />
                </>
            )}
          <Route path="/register" element={<InviteAccepted />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
  );
};

export default App;
