import { useCallback, useEffect, useState } from "react";
import { useRouterManager, Views } from "../../../managers/RouterManager";
import { useMemoryManager } from "../../../managers/MemoryManager";
import "./style/restriction-domain.scss";
import "./style/domain-search.scss";
import SearchBar from "../../../components/search-bar/SearchBar";
import SingleViewList from "../../../components/single-view-list/SingleViewList";
import NewDomainModal from "./components/NewDomainModal";
import WidgetContainer from "../../../components/widget/container/WidgetContainer";
import StatusCard from "../../../components/status-card/StatusCard";

interface DomainBlockRecord {
    domain: string;
    blocked: boolean;
    reason: string;
    effective: string;
}

interface SelectionDetails {
    search?: string;
    isUnderReview: boolean;
    isBlocked: boolean;
    isIsolated: boolean;
    totalUsers: number;
    totalBlockedUsers: number;
    domain?: string;
    history: DomainBlockRecord[];
}

enum StatusCardType {
    ISOLATE = "isolate",
    BAN = "ban" ,
    USER = "user",
    REVIEW = "review",
    BLOCKED = "blocked"
}

const DomainRestrictionView = () => {
    const routerManager = useRouterManager();
    const memoryManager = useMemoryManager();

    const initialDetails: SelectionDetails = memoryManager.getMemory(Views.RMGT_Domain) ?? {
        isUnderReview: false,
        isBlocked: false,
        isIsolated: false,
        totalUsers: 0,
        totalBlockedUsers: 0,
        domain: undefined,
        history: []
    };

    const [domainList, setDomainList] = useState<string[]>([]);
    const [tableData, setTableData] = useState<DomainBlockRecord[]>([]);
    const [selectedDomain, setSelectedDomain] = useState<string>('');
    const [details, setDetails] = useState<SelectionDetails>(initialDetails);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const closeModal = useCallback((created: boolean, newDomain?: string) => {
        if (created && newDomain) {
            setTableData(prevState => [
                ...prevState,
                {
                    domain: newDomain,
                    blocked: false,
                    reason: "New Domain Entry",
                    effective: Date.now().toString()
                }
            ]);
            setSelectedDomain(newDomain);
            pullDomains(newDomain);
        }
        setShowModal(false);
    }, []);

    const handleDomainAction = (domain: string) => {
        const domainRecords = tableData.filter(record => record.domain === domain);
        const latestRecord = domainRecords.sort((a, b) => new Date(b.effective).getTime() - new Date(a.effective).getTime())[0];

        setDetails({
            ...details,
            domain,
            isBlocked: latestRecord ? latestRecord.blocked : false,
            history: domainRecords
        });
        setSelectedDomain(domain);
    };
    const pullDomains = (search: string) => {
        setDetails(prevState => ({ ...prevState, search }));
        setDomainList(
            search
                ? tableData.filter(domain => new RegExp(search, 'i').test(domain.domain)).map(domain => domain.domain)
                : []
        );
    };
    const handleStateCardClicks = (state: StatusCardType) => {
        switch (state) {
            case StatusCardType.USER:
                memoryManager.addMemory(Views.MGT_Account, { lookup: "domain-user", search: details.domain });
                routerManager.changeView(Views.MGT_Account);
                break;
            case StatusCardType.BAN:
                memoryManager.addMemory(Views.MGT_Account, { lookup: "domain-ban", search: details.domain });
                routerManager.changeView(Views.MGT_Account);
                break;
            default:
                // NO ACTIONS
        }
    };

    useEffect(() => {
        //memoryManager.addMemory(Views.RMGT_Domain, details);
    }, [details]);
    useEffect(() => {
        fetch('/data/ban-domains.json')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                setTableData(data);
                setError(null);
                pullDomains(details.search ?? "");
            })
            .catch((error) => {
                setError(error.message);
            });
    }, []);

    return (
        <>
            {showModal && <NewDomainModal onClose={closeModal} />}
            <div id="RestrictionDomainView">
                <div id="domain-list">
                    <SearchBar
                        size="Lx0"
                        search={pullDomains}
                        inputPattern="^[a-zA-Z0-9.\\-]+$"
                        inputPatternInstruction="Domains are alphanumeric and may contain dots and/or dashes."
                        placeholder="Search for a domain"
                        useSecondButton={true}
                        secondButtonDisabled={false}
                        secondButtonCaption="New Domain"
                        secondButtonClick={() => setShowModal(true)}
                    />
                    <SingleViewList
                        size="Lx0"
                        list={domainList}
                        highlightSelection={true}
                        selectedItem={selectedDomain}
                        onSelect={handleDomainAction}
                        initialPageSize={17}
                        pageSizeOptions={[17]}
                    />
                </div>
                <div className="selected-view">
                    <WidgetContainer id="domain-restriction" size="Xx0">
                        <h1 className={details.domain ? undefined : 'no-selection'}>
                            {details.domain ? details.domain.toUpperCase() : "Pending Domain Selection"}
                        </h1>
                        <div className="state-card-container">
                            {details.domain && (
                                <>
                                    <StatusCard onClick={() => handleStateCardClicks(StatusCardType.REVIEW)} title="Reviewed" checked={details.isUnderReview} />
                                    <StatusCard onClick={() => handleStateCardClicks(StatusCardType.BLOCKED)} title="Blocked" checked={details.isBlocked} />
                                    <StatusCard onClick={() => handleStateCardClicks(StatusCardType.ISOLATE)} title="Isolated" checked={details.isIsolated} />
                                    <StatusCard onClick={() => handleStateCardClicks(StatusCardType.USER)} title="Users" count={details.totalUsers} />
                                    <StatusCard onClick={() => handleStateCardClicks(StatusCardType.BAN)} title="Ban" count={details.totalBlockedUsers} />
                                </>
                            )}
                        </div>
                    </WidgetContainer>
                    <WidgetContainer id="domain-history" size="Xx1">
                        <h1>History</h1>
                        <div className="table-container">
                            <table>
                                <thead>
                                <tr>
                                    <th>Blocked</th>
                                    <th>Effective</th>
                                    <th>Reason</th>
                                </tr>
                                </thead>
                                <tbody>
                                {details.history.length > 0 ? (
                                    details.history.map((item, index) => (
                                        <tr key={index} className={index % 2 === 0 ? "row-even" : "row-odd"}>
                                            <td>{item.blocked ? "Y" : "N"}</td>
                                            <td>{item.effective}</td>
                                            <td>{item.reason}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td data-404="1" colSpan={3}>No results found</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </WidgetContainer>
                </div>
            </div>
        </>
    );
};

export default DomainRestrictionView;
export type { DomainBlockRecord };
