import React, {useEffect} from "react";
import { Typography, Container } from "@mui/material";

const Dashboard: React.FC = () => {


    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Dashboard
            </Typography>
            <Typography variant="body1">
                Welcome to the dashboard. You are now logged in.
            </Typography>
        </Container>
    );
};

export default Dashboard;
