import SearchBar from "../../../../components/search-bar/SearchBar";
import WidgetContainer from "../../../../components/widget/container/WidgetContainer";
import DynamicViewList from "../../../../components/dynamic-view-list/DynamicViewList";
import {useState} from "react";
import "../style/search-account-modal.scss";

interface SearchAccountModalProps {
    close: (selected: boolean, account?: string) => void;
}

const SearchAccountModal: React.FC<SearchAccountModalProps> = ({
    close
}) => {
    const [selected, setSelected] = useState<string>('');
    const search = (search: string) => { }
    const selection = (account: string) => {
        setSelected(account);
    }

    return (
        <WidgetContainer id={"AccountSearchModal"} size={"Lx0"}>
            <SearchBar
                size="Lx0"
                search={search}
                inputPattern="^[a-zA-Z0-9.\\-]+$"
                inputPatternInstruction="Domains are alphanumeric and may contain dots and/or dashes."
                placeholder="Search for a Account"
                useSecondButton={true}
                secondButtonDisabled={false}
                secondButtonCaption="Cancel"
                secondButtonClick={() => {close(false);}}
            />
            <DynamicViewList
                size="Lx0"
                list={[
                    { email: "john.doe@coding-standards.com", fname: "John", lname: "Doe" },
                    { email: "jane.doe@coding-standards.com", fname: "Jane", lname: "Doe" },
                    { email: "jane.doe@coding-standards.com", fname: "Jane", lname: "Doe" },
                    { email: "jane.doe@coding-standards.com", fname: "Jane", lname: "Doe" },
                    { email: "jane.doe@coding-standards.com", fname: "Jane", lname: "Doe" },
                    { email: "jane.doe@coding-standards.com", fname: "Jane", lname: "Doe" },
                    { email: "jane.doe@coding-standards.com", fname: "Jane", lname: "Doe" },
                    { email: "jane.doe@coding-standards.com", fname: "Jane", lname: "Doe" },
                    { email: "jane.doe@coding-standards.com", fname: "Jane", lname: "Doe" },
                    { email: "jane.doe@coding-standards.com", fname: "Jane", lname: "Doe" },
                    { email: "jane.doe@coding-standards.com", fname: "Jane", lname: "Doe" }
                ]}
                highlightSelection={true}
                selectedItem={""}
                onSelect={selection}
                initialPageSize={5}
                pageSizeOptions={[5]}
                columns={{
                    key: "email",
                    config: [
                        { header: "Email", key: "email" },
                        { header: "FirstName", key: "fname" },
                        { header: "LastName", key: "lname" }
                    ]
                 }}

            />
        </WidgetContainer>
    )
}

export default SearchAccountModal;