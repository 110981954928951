// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inactivity-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.inactivity-modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 100%;
}
.inactivity-modal h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.inactivity-modal p {
  font-size: 1rem;
  margin-bottom: 15px;
}
.inactivity-modal .countdown {
  color: red;
  font-weight: bold;
}
.inactivity-modal .inactivity-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}
.inactivity-modal .inactivity-button:hover {
  background: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/styles/InactivityMonitor.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,yCAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;AACF;AACE;EACE,iBAAA;EACA,mBAAA;AACJ;AAEE;EACE,eAAA;EACA,mBAAA;AAAJ;AAGE;EACE,UAAA;EACA,iBAAA;AADJ;AAIE;EACE,mBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,2BAAA;AAFJ;AAII;EACE,mBAAA;AAFN","sourcesContent":[".inactivity-overlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background: rgba(0, 0, 0, 0.5);\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  z-index: 1000;\r\n}\r\n\r\n.inactivity-modal {\r\n  background: white;\r\n  padding: 20px;\r\n  border-radius: 10px;\r\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\r\n  text-align: center;\r\n  max-width: 400px;\r\n  width: 100%;\r\n\r\n  h2 {\r\n    font-size: 1.5rem;\r\n    margin-bottom: 10px;\r\n  }\r\n\r\n  p {\r\n    font-size: 1rem;\r\n    margin-bottom: 15px;\r\n  }\r\n\r\n  .countdown {\r\n    color: red;\r\n    font-weight: bold;\r\n  }\r\n\r\n  .inactivity-button {\r\n    background: #007bff;\r\n    color: white;\r\n    border: none;\r\n    padding: 10px 15px;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n    transition: background 0.3s;\r\n\r\n    &:hover {\r\n      background: #0056b3;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
