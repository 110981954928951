import { useState, useMemo, ReactNode, useRef, useEffect } from "react";
import WidgetContainer, { ContainerSize } from "../widget/container/WidgetContainer";
import "./style/dynamic-view-list.scss";

interface ColumnConfig {
    header: string;
    key: string;
}

interface DynamicViewListProps {
    size: ContainerSize;
    onSelect: (selection: string) => void;
    list: Record<string, any>[]; // Accepts a list of generic objects to handle dynamic columns
    columns: { key: string, config: ColumnConfig[]}; // Column configuration for dynamic columns
    selectedItem?: string;
    highlightSelection?: boolean;
    initialPageSize?: number;
    pageSizeOptions?: number[];
    emptyListView?: ReactNode;
}

const DynamicViewList: React.FC<DynamicViewListProps> = ({
   size,
   onSelect,
   list,
   columns,
   selectedItem,
   highlightSelection = false,
   initialPageSize = 10,
   pageSizeOptions = [5, 10, 20, 50],
   emptyListView = <h2>NO SEARCH RESULTS</h2>
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const totalPages = Math.ceil(list.length / pageSize);
    const paginatedData = useMemo(() => {
        const startIdx = (currentPage - 1) * pageSize;
        return list.slice(startIdx, startIdx + pageSize);
    }, [list, currentPage, pageSize]);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(Math.max(1, Math.min(newPage, totalPages)));
    };
    const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
        setCurrentPage(1);  // Reset to first page on page size change
    };
    const tableContainerRef = useRef<HTMLDivElement>(null);

    const getSizeList = () => {
        return Array.from(new Set([...pageSizeOptions])).sort((a, b) => a - b);
    };

    return (
        <WidgetContainer id="dynamic-list" size={size}>
            {list.length === 0 ? (
                emptyListView
            ) : (
                <>
                    <div className="table-container" ref={tableContainerRef}>
                        <table>
                            <colgroup>
                                {columns.config.map((col, index) => (
                                    <col key={index} className={`field-${col.key}`} />
                                ))}
                            </colgroup>
                            <thead>
                            <tr>
                                {columns.config.map((col) => (
                                    <th key={col.key}>{col.header}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {paginatedData.map((row, index) => (
                                <tr
                                    key={index}
                                    className={highlightSelection && selectedItem === row[columns.config[0].key] ? "selected" : undefined}
                                    onClick={() => onSelect(row[columns.key])}
                                >
                                    {columns.config.map((col) => (
                                        <td key={col.key}>{row[col.key]}</td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination-controls">
                        { totalPages > 2 && (
                        <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>First</button>
                        )}
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                        <span>{currentPage} of {totalPages}</span>
                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                        { totalPages > 2 && (
                        <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>Last</button>
                        )}
                        {pageSizeOptions.length > 1 && (
                            <label>
                                <select value={pageSize} onChange={handlePageSizeChange}>
                                    {getSizeList().map((size) => (
                                        <option key={size} value={size}>
                                            {size}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        )}
                    </div>
                </>
            )}
        </WidgetContainer>
    );
};

export default DynamicViewList;
