import "./style/widget-container.scss";

/**
 * Size of the Container by Length x Height<br/>
 * S = Small<br/>
 * M = Medium<br/>
 * H = Large<br/>
 * X = 100%<br/>
 * 1 = Flex: 1<br/>
 * F = Fit Content<br/>
 */
type ContainerSize =
    "SxS" | "SxM" | "SxL" | "SxF"| "SxX" | "Sx1" | "Sx0" |
    "MxS" | "MxM" | "MxL" | "MxF"| "MxX" | "Mx1" | "Mx0" |
    "LxS" | "LxM" | "LxL" | "LxF"| "LxX" | "Lx1" | "Lx0" |
    "FxS" | "FxM" | "FxL" | "FxF"| "FxX" | "Fx1" | "Fx0" |
    "XxS" | "XxM" | "XxL" | "XxF"| "XxX" | "Xx1" | "Xx0" |
    "1xS" | "1xM" | "1xL" | "1xF"| "1xX" | "1x1" | "1x0" |
    "0xS" | "0xM" | "0xL" | "0xF"| "0xX" | "0x1" | "0x0" ;

const WidgetContainer = (props: {id: string, size:ContainerSize,  children: any, className?: string  }) => {

    const getHeight = (): string => {
        return props.size.substr(props.size.length - 1, 1);
    }

    const getWidth = (): string => {
        return props.size.substr(0, 1);
    }

    return (
        <div
            id={props.id}
            className={`widget ${props.className}`}
            data-width={getWidth()}
            data-height={getHeight()}>
            {props.children}
        </div>
    )
}

export default WidgetContainer;
export type {ContainerSize}