// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#UserAccountView {
  position: relative;
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/views/User/account/style/user-account-view.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AACF","sourcesContent":["#UserAccountView {\r\n  position: relative;\r\n  height: 100%;\r\n  width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
