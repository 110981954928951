import React, { createContext, useContext, useCallback, useState, ReactNode } from "react";

// Define types for the context
interface ManagerContextProps {
    changeView: (view: Views) => void;
    getCurrentView: () => Views;
}

interface ManagerProviderProps {
    children: ReactNode;
}

// Define available views as an enum for consistency
enum Views {
    Dashboard = "Dashboard",
    SYS_Health = "SYS_Health",
    SYS_Network = "SYS_Network",
    SYS_Services = "SYS_Services",
    SYS_Maintenance = "SYS_Maintenance",
    RMGT_Domain = "RMGT_Domain",
    RMGT_User_Bans = "RMGT_User_Bans",
    RMGT_IP_Bans = "RMGT_IP_Bans",
    MGT_Account = "MGT_Account",
    MGT_Team = "MGT_Team",
    MGT_Template = "MGT_Template",
    MGT_Review = "MGT_Review"
}

// Initialize context with an empty object casted to the interface
const ManagerContext = createContext<ManagerContextProps | undefined>(undefined);

// Custom hook for accessing the context manager
const useRouterManager = (): ManagerContextProps => {
    const context = useContext(ManagerContext);
    if (!context) {
        throw new Error("useRouterManager must be used within a RouterManagerProvider");
    }
    return context;
};

// Provider component
const RouterManagerProvider: React.FC<ManagerProviderProps> = ({ children }) => {
    const [currentView, setCurrentView] = useState<Views>(Views.Dashboard);

    const changeView = useCallback((view: Views) => {setCurrentView(view);}, []);



    const getCurrentView = useCallback(() => currentView, [currentView]);
    // Memoize the context to avoid unnecessary renders
    const contextValue = React.useMemo(() => ({
        changeView,
        getCurrentView
    }), [changeView, getCurrentView]);





    return (
        <ManagerContext.Provider value={contextValue}>
            {children}
        </ManagerContext.Provider>
    );
};

export default RouterManagerProvider;
export { useRouterManager, Views };
