import logo from '../../resources/logo.svg';
import React, {useState} from "react";
import NavItem from "./components/NavItem";
import "./style/nav-styler.scss";
import { MdDashboard, MdHealthAndSafety, MdExpandLess, MdExpandMore } from 'react-icons/md';
import { FaNetworkWired, FaBan } from 'react-icons/fa';
import { BiShieldQuarter, BiUserCheck, BiBlock, BiCalendar, BiBook } from 'react-icons/bi';
import { AiOutlineTeam } from 'react-icons/ai';
import { BsCardChecklist } from 'react-icons/bs';
import { HiOutlineTemplate } from 'react-icons/hi';
import { RiFileList3Line } from 'react-icons/ri';
import {useRouterManager, Views} from "../../managers/RouterManager"; // Replacement for service logs

interface NavBarProps {

}

const NavBar = (props: NavBarProps) => {
    const routerManager =  useRouterManager();
    const IconSize: number = 24;
    const ControlIconSize: number = 20;

    const [expandedSystemSubMenu, showSystemSubMenu] = useState<boolean>(true);
    const [expandedRestrictionsSubMenu, showRestrictionsSubMenu] = useState<boolean>(true);
    const [expandedUserSubMenu, showUserSubMenu] = useState<boolean>(true);

    const [hoveringSystemSubMenu, setHoveringSystemSubMenu] = useState<boolean>(false);
    const [hoveringRestrictionsSubMenu, setHoveringRestrictionsSubMenu] = useState<boolean>(false);
    const [hoveringUserSubMenu, setHoveringUserSubMenu] = useState<boolean>(false);


    const ItemClicked = (view: Views) => {
        routerManager.changeView(view);
    }

    const currentView: Views = routerManager.getCurrentView();

    return (
        <nav
            className={"expanded"}
        >
            <div id={"app-info"}>
                <img src={logo} className="App-logo" alt="logo" />
                <h4>Coding Standards<br/>Support</h4>
            </div>
            <div id={"nav-menu"}>
                <NavItem key={"nav-item-dashboard"} title={"Dashboard"} active={currentView == Views.Dashboard} onClick={() => {ItemClicked(Views.Dashboard);}} icon={<MdDashboard size={IconSize}/>} />
                <div
                    onMouseOver={()=>setHoveringSystemSubMenu(true)}
                    onMouseLeave={()=>setHoveringSystemSubMenu(false)}
                    className="nav-sub-menu">
                    <div className={"nav-sub-menu-title"}>
                        <h5 className={`${currentView.startsWith("SYS_") && !(expandedSystemSubMenu || hoveringSystemSubMenu)? "selected" : ""}`}>System Management</h5>
                        <div className={"nav-sub-menu-icon"}  onClick={() => showSystemSubMenu(prevState => !prevState)}>
                            {expandedSystemSubMenu ? <MdExpandLess size={ControlIconSize} /> : <MdExpandMore size={ControlIconSize}/>}
                        </div>
                    </div>
                    <div className={`nav-sub-menu-items ${expandedSystemSubMenu || hoveringSystemSubMenu ? "" : "collapsed"}`}>
                        <NavItem key={"nav-item-sys-health"} title={"Health"} active={currentView== Views.SYS_Health} onClick={() => {ItemClicked(Views.SYS_Health);}} icon={<MdHealthAndSafety  size={IconSize} />} />
                        <NavItem key={"nav-item-sys-abuse"} title={"Network"} active={currentView == Views.SYS_Network} onClick={() => {ItemClicked(Views.SYS_Network);}} icon={<FaNetworkWired  size={IconSize} />} />
                        <NavItem key={"nav-item-sys-logs"} title={"Services"} active={currentView == Views.SYS_Services} onClick={() => {ItemClicked(Views.SYS_Services);}} icon={<RiFileList3Line  size={IconSize} />} />
                        <NavItem key={"nav-item-sys-maintenance"} title={"Maintenance"} active={currentView == Views.SYS_Maintenance} onClick={() => {ItemClicked(Views.SYS_Maintenance);}} icon={<BiCalendar  size={IconSize} />} />
                        </div>
                </div>
                <div
                    onMouseOver={()=>setHoveringRestrictionsSubMenu(true)}
                    onMouseLeave={()=>setHoveringRestrictionsSubMenu(false)}
                    className="nav-sub-menu">
                    <div className={"nav-sub-menu-title"}>
                        <h5 className={`${currentView.startsWith("RMGT_") && !(expandedRestrictionsSubMenu || hoveringRestrictionsSubMenu)? "selected" : ""}`}>Restrictions Management</h5>
                        <div className={"nav-sub-menu-icon"}  onClick={() => showRestrictionsSubMenu(prevState => !prevState)}>
                            {expandedRestrictionsSubMenu ? <MdExpandLess size={ControlIconSize} /> : <MdExpandMore size={ControlIconSize}/>}
                        </div>
                    </div>
                    <div className={`nav-sub-menu-items ${expandedRestrictionsSubMenu || hoveringRestrictionsSubMenu ? "" : "collapsed"}`}>
                        <NavItem key={"nav-item-rmgt-domain"} title={"Domain"} active={currentView == Views.RMGT_Domain} onClick={() => {ItemClicked(Views.RMGT_Domain);}} icon={<BiShieldQuarter  size={IconSize} />} />
                        <NavItem key={"nav-item-rmgt-user-bans"} title={"User"} active={currentView == Views.RMGT_User_Bans} onClick={() => {ItemClicked(Views.RMGT_User_Bans);}} icon={<FaBan  size={IconSize} />} />
                        <NavItem key={"nav-item-rmgt-ip-bans"} title={"IP"} active={currentView == Views.RMGT_IP_Bans} onClick={() => {ItemClicked(Views.RMGT_IP_Bans);}} icon={<BiBlock  size={IconSize} />} />
                    </div>
                </div>
                <div
                    onMouseOver={()=>setHoveringUserSubMenu(true)}
                    onMouseLeave={()=>setHoveringUserSubMenu(false)}
                    className="nav-sub-menu">
                    <div className={"nav-sub-menu-title"}>
                        <h5 className={`${currentView.startsWith("MGT_") && !(expandedUserSubMenu || hoveringUserSubMenu)? "selected" : ""}`}>User Management</h5>
                        <div className={"nav-sub-menu-icon"}  onClick={() => showUserSubMenu(prevState => !prevState)}>
                            {expandedUserSubMenu ? <MdExpandLess size={ControlIconSize} /> : <MdExpandMore size={ControlIconSize}/>}
                        </div>
                    </div>
                    <div className={`nav-sub-menu-items ${expandedUserSubMenu || hoveringUserSubMenu ? "" : "collapsed"}`}>
                        <NavItem key={"nav-item-mgt-account"} title={"Account"} active={currentView == Views.MGT_Account} onClick={() => {ItemClicked(Views.MGT_Account);}} icon={<BiUserCheck  size={IconSize} />} />
                        <NavItem key={"nav-item-mgt-team"} title={"Team"} active={currentView == Views.MGT_Team} onClick={() => {ItemClicked(Views.MGT_Team);}} icon={<AiOutlineTeam  size={IconSize} />} />
                        <NavItem key={"nav-item-mgt-template"} title={"Template"} active={currentView == Views.MGT_Template} onClick={() => {ItemClicked(Views.MGT_Template);}} icon={<HiOutlineTemplate  size={IconSize} />} />
                        <NavItem key={"nav-item-mgt-review"} title={"Review"} active={currentView == Views.MGT_Review} onClick={() => {ItemClicked(Views.MGT_Review);}} icon={<BsCardChecklist  size={IconSize} />} />
                    </div>
                </div>
            </div>
            <div id={"nav-account"}>

            </div>
        </nav>
    )
}

export default NavBar;