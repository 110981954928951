// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-management {
  padding: 20px;
}
.user-management h2 {
  margin: 0;
}
.user-management .MuiTableContainer-root {
  margin-top: 20px;
}
.user-management .MuiTableCell-root {
  text-align: center;
}
.user-management .MuiButton-root {
  margin: 0 5px;
}
.user-management .header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.user-management .header Button {
  height: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/UserManagement.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,SAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,kBAAA;AADJ;AAIE;EACE,aAAA;AAFJ;AAKE;EACE,aAAA;EACA,aAAA;EACA,8BAAA;EACA,qBAAA;AAHJ;AAII;EACE,YAAA;AAFN","sourcesContent":[".user-management {\r\n  padding: 20px;\r\n\r\n  h2 {\r\n    margin: 0;\r\n  }\r\n\r\n  .MuiTableContainer-root {\r\n    margin-top: 20px;\r\n  }\r\n\r\n  .MuiTableCell-root {\r\n    text-align: center;\r\n  }\r\n\r\n  .MuiButton-root {\r\n    margin: 0 5px;\r\n  }\r\n\r\n  .header {\r\n    padding: 10px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-content: center;\r\n    Button {\r\n      height: 2rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
