import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Input, Typography, Box
} from "@mui/material";
import { useApi } from "../context/ApiContext";
import "../styles/UserManagement.scss";

interface UserData {
    username: string;
    email: string;
    status: string;
    lastLogin: string;
    loggedIn: boolean;
}

interface UserManagementProps {

}

const UserManagement: React.FC<UserManagementProps> = ({}) => {
    const api = useApi();
    const [users, setUsers] = useState<UserData[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
    const [dialogAction, setDialogAction] = useState("");
    const inviteEmail = useRef("");

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = () => {
        api.get("/account/user").then(
            (response) => {
                setUsers(response.data.users);
            }
        );
    };

    const handleAction = (user: UserData, action: "disable" | "enable" | "delete")=> {
        setSelectedUser(user);
        setDialogAction(action);
        setOpenDialog(true);
    };

    const handleInvite= () => {
        setDialogAction("invite");
        setOpenDialog(true);
    };

    const confirmAction = async () => {
        const postAction = () => {
            setOpenDialog(false);
            fetchUsers();
        }
        switch (dialogAction) {
            case "disable":
            case "enable":
                api.post(`/account/user`, {username: selectedUser?.username, request: dialogAction}).then(postAction);
                break;
            case "delete": api.delete(`/account/user`, {username: selectedUser?.username}).then(postAction); break;
            case "invite": api.post(`/account/invite`, {email: inviteEmail.current}).then(postAction); break;
        }
    };

    return (
        <div className="user-management">
            <Box className={"header"}>
                <h2>Manage Users</h2>
                <Button variant={"contained"} onClick={() => handleInvite()} color="primary">Invite</Button>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Online</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Last Login</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.email}>
                                <TableCell>{user.loggedIn ? "Online" : "Offline"}</TableCell>
                                <TableCell>{user.status}</TableCell>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{new Date(user.lastLogin).toLocaleString()}</TableCell>
                                <TableCell>
                                    <Button variant={"contained"} onClick={() => handleAction(user, user.status === "Active" ? "disable" : "enable")} color="warning">{user.status === "Active" ? "Disable" : "Enable"}</Button>
                                    <Button variant={"contained"} onClick={() => handleAction(user, "delete")} color="error">Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>

                    {dialogAction === "invite" && (
                        <>
                            <Typography>Enter Email:</Typography>
                            <Input onChange={(e) => inviteEmail.current = e.target.value} />
                        </>
                    )}
                    {dialogAction !== "invite" && (`Are you sure you want to {dialogAction} {selectedUser?.username}?`)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={confirmAction} color="primary">Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UserManagement;
