import React, { useState } from "react";
import { useUser } from "../context/UserContext"; // Import the User context
import { TextField, Button, Container, Typography } from "@mui/material";

interface LoginProps {
    closer?: any
}

const Login: React.FC<LoginProps> = ({closer}) => {
    const { login } = useUser(); // Get the login function from the context
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    const getDeviceID = () : string => {
        let deviceId = localStorage.getItem("device-id");
        if (!deviceId) {
            deviceId = `${Math.random().toString(36).substr(2, 9)}-${Date.now()}`;
            localStorage.setItem("device-id", deviceId);
        }
        return deviceId;
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        try {
            login({username: email, password: password, deviceId: getDeviceID()}).then(
                response => {
                    if(response === "logged In") {
                        setError(null); // Clear error if login is successful
                    }else {
                        setError("Invalid credentials. Please try again.");
                    }
                }
            );
        } catch (e) {
            setError("Invalid credentials. Please try again.");
        }
    };

    return (
        <Container maxWidth="xs">
            <Typography variant="h4" gutterBottom>
                Login
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="User"
                    fullWidth
                    required
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="Password"
                    fullWidth
                    required
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                />
                {error && <Typography color="error">{error}</Typography>}
                <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 2 }}>
                    Login
                </Button>
                {!!closer && (
                    <Button type="button" fullWidth variant="contained" color="error" sx={{ mt: 2 }} onClick={closer}>
                        Cancel
                    </Button>
                )}
            </form>
        </Container>
    );
};

export default Login;
