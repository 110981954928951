import React from "react";

const NavItem = (props: {title: string, icon: any, active: boolean, onClick: any}) => {
    return (
        <div
            className={`nav-item ${props.active ? 'selected' : ''}`}
            onClick={props.onClick}
        >
            <h4>{props.title}</h4>
            {props.icon}
        </div>
    )
}

export default NavItem;