import React, {createContext, JSX, ReactNode, RefObject, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useUser} from "./UserContext";
import {useApi} from "./ApiContext";

const BOOKMARK_API_PATH = "/bookmarks";
interface Bookmark {
    id: string;
    title: string;
    url: string;
    imageUrl: string | null;
    folder: string;
    monitoringEnabled: boolean;
    monitoringUrl: string;
    monitoringStatus: string;
    updatedAt: string;
}
interface BookmarkForm {
    title: string;
    url: string;
    imageUrl: string;
    folder: string;
    monitoringEnabled: boolean;
    monitoringUrl: string;
}
interface BookmarkContextProps {
    pull: () => Promise<Bookmark[]>;
    get: () => Promise<Bookmark[]>;
    create: (form: BookmarkForm) => Promise<boolean>;
    update: (form: BookmarkForm, bookmark: Bookmark) => Promise<boolean>;
    delete: (bookmark: Bookmark) => Promise<boolean>;
    open: (bookmark: Bookmark) => Promise<void>;
    status:  RefObject<{[p: string]: "online" | "offline" | "undetermined"}>;
}
interface BookmarkProviderProps {
    children: ReactNode;
}

const BookmarkContext = createContext<BookmarkContextProps | undefined>(undefined);
const useBookmark = (): BookmarkContextProps => {
    const context = useContext(BookmarkContext);
    if (!context) {
        throw new Error("useBookmark must be called from within the BookmarkProvider");
    }

    return context;
}
const BookmarkProvider = ({children}: BookmarkProviderProps): JSX.Element => {

    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const api = useApi();
    const user = useUser();
    const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
    const bookmarkStatus = useRef<{[key: string]: "online" | "offline" | "undetermined"}>({});

    const mergeBookmark = (form: BookmarkForm, bookmark?: Bookmark): Bookmark => {
        return {
            id: bookmark?.id || "",
            title: form.title,
            url: form.url,
            imageUrl: form.imageUrl,
            folder: form.folder,
            monitoringStatus: bookmark?.monitoringStatus || "active",
            monitoringEnabled: form.monitoringEnabled,
            monitoringUrl: form.monitoringUrl,
            updatedAt: new Date().toISOString()
        }
    }

    useEffect(() => {
        if(!user.user && bookmarks.length > 0) {
            setBookmarks([]);
        }
    }, [user.user]);

    const pullBookmarks = async (): Promise<Bookmark[]> => {
        if(user.user) {
            return api.get(BOOKMARK_API_PATH)
                .then((response) => {
                    if (response.status in [403, 401]) {
                        user.logout();
                        return bookmarks;
                    }
                    setBookmarks(response.data.bookmarks);
                    //checkBookmarkStatus(response.data.bookmarks);
                    return response.data.bookmarks;
                }).catch((error) => {
                    // API FAILED....
                    return bookmarks;
                });
        }
        return bookmarks;
    }
    const getBookmarks = async (): Promise<Bookmark[]> => {
        if(bookmarks && bookmarks.length !== 0) return bookmarks;
        return await pullBookmarks();
    }
    const openBookmark = async (bookmark: Bookmark): Promise<void> => {
        api.newTab(bookmark.url);
    }
    const deleteBookmark = async (bookmark: Bookmark): Promise<boolean> => {
        return api.delete(BOOKMARK_API_PATH, {id: bookmark.id})
            .then((response) => {
                switch(response.status){
                    case 200: pullBookmarks();  return true;
                    case 403:
                    case 401:
                        user.logout();
                    // Fallthrough
                    default:
                        return false;
                }
            }).catch((error)=>{
                // API FAILED....
                return false;
            });
    }
    const updateBookmark = async (form: BookmarkForm, bookmark: Bookmark): Promise<boolean> => {
        return api.put(BOOKMARK_API_PATH, mergeBookmark(form, bookmark))
            .then((response) => {
                switch(response.status){
                    case 200: pullBookmarks();  return true;
                    case 403:
                    case 401:
                        user.logout();
                    // Fallthrough
                    default:
                        return false;
                }
            }).catch((err) => {
                return false;
            });
    }
    const createBookmark = async (form: BookmarkForm): Promise<boolean> => {
        return api.post(BOOKMARK_API_PATH, mergeBookmark(form))
            .then((response) => {
                switch(response.status){
                    case 200: pullBookmarks(); return true;
                    case 403:
                    case 401:
                        user.logout();
                    // Fallthrough
                    default:
                        return false;
                }
            }).catch((err) => {
            return false;
        });
    }

    const checkBookmarkStatus = async (bookmarkList: Bookmark[]) => {
        for (let i = 0; i < bookmarkList.length; i++) {
            const bookmark = bookmarkList[i];
            if (iframeRef.current && "src" in iframeRef.current) {
                iframeRef.current.src = api.checkUrl(bookmark.url);
                await new Promise((resolve) => {

                    const timeout = setTimeout(() => {
                        bookmarkStatus.current[bookmark.url] = "offline";
                        cleanup();
                        resolve(false);
                    }, 1500); // Set timeout to 1.5 seconds


                    const handleLoad = () => {
                        clearTimeout(timeout);
                        bookmarkStatus.current[bookmark.url] = "online";
                        cleanup();
                        resolve(true);
                    };

                    const handleError = () => {
                        clearTimeout(timeout);
                        bookmarkStatus.current[bookmark.url] = "offline";
                        cleanup();
                        resolve(false);
                    };

                    const cleanup = () => {
                        if (iframeRef.current && "removeEventListener" in iframeRef.current) {
                            iframeRef.current.removeEventListener("load", handleLoad);
                            iframeRef.current.removeEventListener("error", handleError);
                        }
                    };

                    if (iframeRef.current && "addEventListener" in iframeRef.current) {
                        iframeRef.current.addEventListener("load", handleLoad);
                        iframeRef.current.addEventListener("error", handleError);
                    }
                });
            }
        }
    }

    const bookmark = {
        pull: async (): Promise<Bookmark[]> => pullBookmarks(),
        get: async (): Promise<Bookmark[]> => getBookmarks(),
        create: async (form: BookmarkForm): Promise<boolean> => createBookmark(form),
        delete: async (bookmark: Bookmark): Promise<boolean> => deleteBookmark(bookmark),
        update: async (form: BookmarkForm, bookmark: Bookmark): Promise<boolean>  => updateBookmark(form,bookmark),
        open: async (bookmark: Bookmark): Promise<void> => openBookmark(bookmark),
        status: bookmarkStatus
    }

    return (
        <BookmarkContext value={bookmark}>
            <>
                {children}
                <iframe
                    ref={iframeRef}
                    style={{ display: "none" }}
                    referrerPolicy="no-referrer"
                />
            </>
        </BookmarkContext>
    )
}

export default BookmarkProvider;
export {useBookmark};
export type {Bookmark, BookmarkForm};