import {FaCheckCircle, FaCircle} from "react-icons/fa";

interface StatusCardProps {
    title: string;
    checked?: boolean;
    count?: number;
    onClick?: any;
}
const StatusCard = (props: StatusCardProps) => {
    const size: number = 35;
    return (
        <div className={"state-card"} onClick={props.onClick}>
            <h3>{props.title}</h3>
            {
                props.checked == undefined ? (<h1>{props.count}</h1> ) :
                    props.checked ? (
                        <FaCheckCircle style={{ color: 'green' }} size={size} />
                    ) : (
                        <FaCircle style={{ color: 'grey' }} size={size} />
                    )}
        </div>
    );
}

export default StatusCard