import React, { useState } from "react";
import { Card, Collapse, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "../styles/FolderTree.scss";
import Folder from "./Folder";

interface FolderTreeProps {
    bookmarks: { folder: string }[];
    onFolderSelect: (folder: string) => void;
}

const buildFolderStructure = (bookmarks: { folder: string }[]) => {
    const tree: Record<string, Set<string>> = {};

    bookmarks.forEach(({ folder }) => {
        const parts = folder.split("/").filter(Boolean);
        let path = "";

        parts.forEach(part => {
            const fullPath = path ? `${path}/${part}` : `/${part}`;
            if (!tree[path]) tree[path] = new Set();
            tree[path].add(fullPath);
            path = fullPath;
        });
    });

    return tree;
};

const FolderTree: React.FC<FolderTreeProps> = ({ bookmarks, onFolderSelect }) => {

    const [selectedFolder, updateSelection] =  useState<string>("");
    const folderTree = buildFolderStructure(bookmarks);
    const [openFolders, setOpenFolders] = useState<Record<string, boolean>>({});

    const toggleFolder = (folder: string) => {
        setOpenFolders(prev => ({ ...prev, [folder]: !prev[folder] }));
    };
    const handleFolderSelection = (folder: string) => {
        updateSelection(folder);
        onFolderSelect(folder);
    }

    const renderFolders = (parent: string) => {
        if (!folderTree[parent]) return null;

        return (
            <>
                {Array.from(folderTree[parent]).map(folder => {
                    const isOpen = parent == "" || openFolders[folder];
                    const folderName = folder.split("/").pop() || "";
                    const hasFolders = folderTree[folder]?.size > 0;

                    return (
                        <div key={folder} className="folder-wrapper">
                            <Folder
                                name={parent === "" ? "Bookmarks" : folderName}
                                isOpen={isOpen}
                                selected={selectedFolder === folder}
                                subFolders={parent != "" && hasFolders}
                                onFolderClick={() => handleFolderSelection(folder)}
                                onFolderDoubleClick={parent != "" ? () => toggleFolder(folder) : undefined}
                            />
                            {hasFolders && (
                                <Collapse in={isOpen} className="subfolderList" timeout="auto" unmountOnExit>
                                    {renderFolders(folder)}
                                </Collapse>
                            )}
                        </div>
                    );
                })}
            </>
        );
    };

    return <Card className="folderList">{renderFolders("")}</Card>;
};

export default FolderTree;
