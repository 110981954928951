import React, {useEffect, useState} from "react";
import {Avatar, Box, Button, Card, CardContent, IconButton, Tooltip, Typography} from "@mui/material";
import {Bookmark} from "../pages/BookmarksPage";
import {Circle} from "@mui/icons-material";
import "../styles/BookmarkItem.scss";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import {useBookmark} from "../context/BookmarkContext";
import BookmarkAvatar from "./BookmarkIcon";


interface BookmarkItemProps {
    bookmark: Bookmark;
    onEditClick: () => void;
}

export const openInNewTab = (url: string): void => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'https://' + url; // Default to HTTPS for security
    }
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer')
    if (newWindow) newWindow.opener = null
}

const BookmarkItem: React.FC<BookmarkItemProps> = ({ bookmark, onEditClick }) => {
    const bookmarkDetails = useBookmark();
    const status: string = bookmarkDetails.status.current[bookmark.url] || "undetermined";

    const getStatusColor = (v: string | undefined) => {
        switch (v) {
            case "online": return "#04a604";
            case "offline": return "#d93838";
            default: return "#cccccc";
        }
    };

    return (
        <Card className="bookmark" >
            <CardContent className="bookmark-details">
                <Box className="section-a">
                    <BookmarkAvatar
                        bookmark={{
                            url: bookmark.url,
                            imageUrl: bookmark.imageUrl || undefined,
                            monitoringStatus: bookmark.monitoringStatus
                        }}
                        open={() => openInNewTab(bookmark.url)}
                    />
                    {/*<Avatar className="logo"*/}
                    {/*        style={{borderColor: getStatusColor(bookmark.monitoringStatus?.toLowerCase())}}*/}
                    {/*        onClick={() => openInNewTab(bookmark.url)}*/}
                    {/*>*/}
                    {/*    <BookmarkIcon />*/}
                    {/*</Avatar>*/}
                </Box>
                <Button className="section-b" onClick={() => openInNewTab(bookmark.url)}>
                    <Typography variant="h6" align="center" className="bookmark-title">
                        {bookmark.title}
                    </Typography>
                    <Typography className="bookmark-url">{bookmark.url}</Typography>
                </Button>
                <Box className="section-c">
                    <Button variant="contained" size="small" onClick={onEditClick}>Edit</Button>
                    { bookmark.monitoringEnabled && (
                        <Button variant="contained" size="small">History</Button>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};


export default BookmarkItem;