import SearchAccountModal from "./components/SearchAccountModal";
import {useState} from "react";
import "./style/user-account-view.scss";


const View = () => {
    const [showSearchModal, setSearchModalVisibility] = useState(false);


    const searchClosed = (selected: boolean, account?: string) => {
        setSearchModalVisibility(false);
    }

    return (
        <div id={"UserAccountView"}>
            <button onClick={() => setSearchModalVisibility(true)} >Search</button>
            {showSearchModal && (
                <SearchAccountModal close={searchClosed}/>
            )}
        </div>
    )
}

export default View;