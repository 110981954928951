// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#domain-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
}
#domain-list #domain-options button {
  padding: 1em 1.5em;
  border-radius: 5em;
  margin: 0;
  background: #282c34;
  color: #c4c4c4;
}
#domain-list #domain-options button:hover {
  background: rgb(62.1739130435, 68.3913043478, 80.8260869565);
}
#domain-list #domain-options button:disabled {
  background: rgb(62.1739130435, 68.3913043478, 80.8260869565);
  color: gray;
}

#domain-restriction {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#domain-restriction .no-selection {
  width: 100%;
  -webkit-user-select: none;
          user-select: none;
  text-align: center !important;
  color: rgba(0, 0, 0, 0.2);
  padding: 0.85em 0.6em;
}
#domain-restriction .state-card-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
#domain-restriction .state-card-container .state-card {
  -webkit-user-select: none;
          user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 80px;
  height: 80px;
  border-radius: 20px;
}
#domain-restriction .state-card-container .state-card h3, #domain-restriction .state-card-container .state-card h1 {
  margin: 0 0 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/views/Restrictions/domain/style/domain-search.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,SAAA;AACF;AAEI;EACE,kBAAA;EACA,kBAAA;EACA,SAAA;EAEA,mBADQ;EAER,cAAA;AADN;AAEM;EACE,4DAAA;AAAR;AAEM;EACE,4DAAA;EACA,WAAA;AAAR;;AAOA;EACE,aAAA;EAEA,8BAAA;EACA,mBAAA;AALF;AAME;EACE,WAAA;EACA,yBAAA;UAAA,iBAAA;EACA,6BAAA;EACA,yBAAA;EACA,qBAAA;AAJJ;AAME;EAEE,aAAA;EACA,mBAAA;EACA,SAAA;AALJ;AAOI;EACE,yBAAA;UAAA,iBAAA;EAKA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,6BAAA;EACA,oCAAA;EACA,aAAA;EACA,WAVO;EAWP,YAXO;EAYP,mBAAA;AATN;AAFM;EACE,kBAAA;AAIR","sourcesContent":["#domain-list {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: 100%;\r\n  gap: 20px;\r\n\r\n  #domain-options {\r\n    button {\r\n      padding: 1em 1.5em;\r\n      border-radius: 5em;\r\n      margin: 0;\r\n      $color: #282c34;\r\n      background: $color;\r\n      color: #c4c4c4;\r\n      &:hover {\r\n        background: lighten($color, 10);\r\n      }\r\n      &:disabled {\r\n        background: lighten($color, 10);\r\n        color: gray;\r\n      }\r\n    }\r\n  }\r\n\r\n}\r\n\r\n#domain-restriction {\r\n  display: flex;\r\n\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  .no-selection {\r\n    width: 100%;\r\n    user-select: none;\r\n    text-align: center !important;\r\n    color: rgba(0,0,0,.2);\r\n    padding: .85em .6em;\r\n  }\r\n  .state-card-container {\r\n\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 20px;\r\n\r\n    .state-card {\r\n      user-select: none;\r\n      $size: 80px;\r\n      h3, h1 {\r\n        margin: 0 0 10px 0;\r\n      }\r\n      display: flex;\r\n      flex-direction: column;\r\n      align-items: center;\r\n      justify-content: space-around;\r\n      background-color: rgba(0,0,0,.1);\r\n      padding: 10px;\r\n      width: $size;\r\n      height: $size;\r\n      border-radius: $size / 4;\r\n    }\r\n  }\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
