import React, {useState} from "react";
import {
    Container,
    Typography,
    Button,
    Grid,
    Paper,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import { useTheme } from "@mui/system";
import Login from "../components/Login";
import {useUser} from "../context/UserContext";

const HomePage: React.FC = () => {
    const user = useUser();
    const navigator = useNavigate();
    const theme = useTheme();
    const [openLogin, setOpenLogin] = useState(false); // State to toggle login form visibility
    const handleLoginDialogOpen = () => {
        if(!!user.user?.username) {
            navigator("/dashboard");
        }else {
            setOpenLogin(true);
        }
    };
    const handleLoginDialogClose = () => {setOpenLogin(false);};

    return (
        <div>
            <Container maxWidth="lg" style={{ padding: theme.spacing(4, 0) }}>
                {/* Hero Section */}
                <Typography variant="h3" gutterBottom align="center">
                    Welcome to Digital Bookmarks
                </Typography>
                <Typography variant="h6" paragraph align="center">
                    Manage your bookmarks and monitor website statuses in real-time.
                </Typography>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLoginDialogOpen}
                        size="large"
                    >
                        Start Managing Bookmarks
                    </Button>
                </div>

                {/* Features Section */}
                <Grid container spacing={4} style={{ marginTop: theme.spacing(4) }}>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} style={{ padding: theme.spacing(3) }}>
                            <Typography variant="h5" gutterBottom>
                                Cloud-Based Storage
                            </Typography>
                            <Typography variant="body1">
                                Access your bookmarks from any device, anytime, anywhere.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} style={{ padding: theme.spacing(3) }}>
                            <Typography variant="h5" gutterBottom>
                                Website Monitoring
                            </Typography>
                            <Typography variant="body1">
                                Get notified if any of your saved websites go down.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} style={{ padding: theme.spacing(3) }}>
                            <Typography variant="h5" gutterBottom>
                                Easy to Use
                            </Typography>
                            <Typography variant="body1">
                                A simple, intuitive interface that makes managing bookmarks a breeze.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Dialog open={openLogin} fullWidth maxWidth="xs">
                <DialogContent>
                    <Login closer={handleLoginDialogClose} /> {/* Render the Login component inside the Dialog */}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default HomePage;
