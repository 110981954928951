// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#NewDomainModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}
#NewDomainModal .container {
  position: relative;
  width: 100%;
  height: fit-content;
}
#NewDomainModal .close-button {
  padding: 1em 1.5em;
  border-radius: 5em;
  background: #8a1d1d;
  color: #c4c4c4;
}
#NewDomainModal .close-button:hover {
  background: rgb(180.1437125749, 37.8562874251, 37.8562874251);
}
#NewDomainModal .close-button:disabled {
  background: rgb(180.1437125749, 37.8562874251, 37.8562874251);
  color: gray;
}
#NewDomainModal form {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
#NewDomainModal form input {
  flex: 1 1;
  padding: 1em 1.5em;
  border-radius: 5em;
}
#NewDomainModal form button {
  padding: 1em 1.5em;
  border-radius: 5em;
  margin: 0;
  background: #282c34;
  color: #c4c4c4;
}
#NewDomainModal form button:hover {
  background: rgb(62.1739130435, 68.3913043478, 80.8260869565);
}
#NewDomainModal form button:disabled {
  background: rgb(62.1739130435, 68.3913043478, 80.8260869565);
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/views/Restrictions/domain/style/new-domain-modal.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EAEA,UAAA;AAAF;AAEE;EACE,kBAAA;EACA,WAAA;EACA,mBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,kBAAA;EAEA,mBADQ;EAER,cAAA;AAFJ;AAGI;EACE,6DAAA;AADN;AAGI;EACE,6DAAA;EACA,WAAA;AADN;AAKE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAHJ;AAKI;EACE,SAAA;EACA,kBAAA;EACA,kBAAA;AAHN;AAKI;EACE,kBAAA;EACA,kBAAA;EACA,SAAA;EAEA,mBADQ;EAER,cAAA;AAJN;AAKM;EACE,4DAAA;AAHR;AAKM;EACE,4DAAA;EACA,WAAA;AAHR","sourcesContent":["#NewDomainModal {\r\n  position: fixed;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n\r\n  z-index: 5;\r\n\r\n  .container {\r\n    position: relative;\r\n    width: 100%;\r\n    height: fit-content;\r\n  }\r\n\r\n  .close-button {\r\n    padding: 1em 1.5em;\r\n    border-radius: 5em;\r\n    $color: #8a1d1d;\r\n    background: $color;\r\n    color: #c4c4c4;\r\n    &:hover {\r\n      background: lighten($color, 10);\r\n    }\r\n    &:disabled {\r\n      background: lighten($color, 10);\r\n      color: gray;\r\n    }\r\n  }\r\n\r\n  form {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 15px;\r\n\r\n    input {\r\n      flex: 1;\r\n      padding: 1em 1.5em;\r\n      border-radius: 5em;\r\n    }\r\n    button {\r\n      padding: 1em 1.5em;\r\n      border-radius: 5em;\r\n      margin: 0;\r\n      $color: #282c34;\r\n      background: $color;\r\n      color: #c4c4c4;\r\n      &:hover {\r\n        background: lighten($color, 10);\r\n      }\r\n      &:disabled {\r\n        background: lighten($color, 10);\r\n        color: gray;\r\n      }\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
