// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #c4c4c4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  text-align: center;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.App main {
  flex: 1 1;
  padding: 20px 20px 20px 20px;
}

.container {
  background: #e8e8e8;
  color: #051128;
  border-radius: 1em;
}`, "",{"version":3,"sources":["webpack://./src/styles/default.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,8JAAA;EAGA,mCAAA;EACA,kCAAA;EACA,mBAAA;AADF;;AAIA;EACE,+EAAA;AADF;;AAKA;EACE,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AAFF;AAGE;EACE,SAAA;EACA,4BAAA;AADJ;;AAMA;EACE,mBAAA;EACA,cAAA;EACA,kBAAA;AAHF","sourcesContent":["body {\r\n  margin: 0;\r\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\r\n  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\r\n  sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  background: #c4c4c4;\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\r\n  monospace;\r\n}\r\n\r\n.App {\r\n  text-align: center;\r\n  height: 100vh;\r\n  overflow: hidden;\r\n  display: flex;\r\n  flex-direction: row;\r\n  main {\r\n    flex: 1;\r\n    padding: 20px 20px 20px 20px;\r\n  }\r\n\r\n}\r\n\r\n.container {\r\n  background: #e8e8e8;\r\n  color: #051128;\r\n  border-radius: 1em;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
