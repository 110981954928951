// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget {
  background: #e8e8e8;
  color: #282c34;
  border-radius: 1.5em;
  padding: 1.5em;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
}
.widget[data-width=S] {
  width: 100px;
}
.widget[data-width=M] {
  width: 300px;
}
.widget[data-width=L] {
  width: 500px;
}
.widget[data-width=X] {
  width: 100%;
}
.widget[data-width="1"] {
  flex: 1 1;
}
.widget[data-width=F] {
  width: max-content;
}
.widget[data-height=S] {
  height: 100px;
}
.widget[data-height=M] {
  height: 300px;
}
.widget[data-height=L] {
  height: 500px;
}
.widget[data-height=X] {
  height: 100%;
}
.widget[data-height="1"] {
  flex: 1 1;
}
.widget[data-height=F] {
  height: max-content;
}`, "",{"version":3,"sources":["webpack://./src/components/widget/container/style/widget-container.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,cAAA;EACA,oBAAA;EACA,cAAA;EACA,2CAAA;AACF;AACE;EAAoB,YAAA;AAEtB;AADE;EAAoB,YAAA;AAItB;AAHE;EAAoB,YAAA;AAMtB;AALE;EAAoB,WAAA;AAQtB;AAPE;EAAoB,SAAA;AAUtB;AATE;EAAoB,kBAAA;AAYtB;AAVE;EAAqB,aAAA;AAavB;AAZE;EAAqB,aAAA;AAevB;AAdE;EAAqB,aAAA;AAiBvB;AAhBE;EAAqB,YAAA;AAmBvB;AAlBE;EAAqB,SAAA;AAqBvB;AApBE;EAAqB,mBAAA;AAuBvB","sourcesContent":[".widget {\r\n  background: #e8e8e8;\r\n  color: #282c34;\r\n  border-radius: 1.5em;\r\n  padding: 1.5em;\r\n  box-shadow: 0 0 12px 1px rgba(0,0,0,.2);\r\n\r\n  &[data-width=\"S\"] { width: 100px; }\r\n  &[data-width=\"M\"] { width: 300px; }\r\n  &[data-width=\"L\"] { width: 500px; }\r\n  &[data-width=\"X\"] { width: 100% }\r\n  &[data-width=\"1\"] { flex: 1; }\r\n  &[data-width=\"F\"] { width: max-content; }\r\n\r\n  &[data-height=\"S\"] { height: 100px; }\r\n  &[data-height=\"M\"] { height: 300px; }\r\n  &[data-height=\"L\"] { height: 500px; }\r\n  &[data-height=\"X\"] { height: 100% }\r\n  &[data-height=\"1\"] { flex: 1; }\r\n  &[data-height=\"F\"] { height: max-content; }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
