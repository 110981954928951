// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folderList {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
}

.folder-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.folder {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #f4f4f4;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;
  justify-content: space-between;
}
.folder:hover {
  background-color: #e0e8fd;
}
.folder svg {
  margin-right: 8px;
  color: #333;
}
.folder .MuiTypography-root {
  font-weight: 500;
  color: #333;
}
.folder.selected {
  background-color: #1975d1;
  color: white;
}
.folder.selected:hover {
  background-color: #1975d1;
  color: white;
}
.folder.selected svg {
  margin-right: 8px;
  color: white;
}
.folder.selected .MuiTypography-root {
  font-weight: 500;
  color: white;
}

.MuiCollapse-wrapperInner {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  gap: 5px;
}

.cardContent {
  display: flex;
  align-items: center;
}

.expandIcon {
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/FolderTree.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,6CAAA;EACA,8BAAA;AACF;AAAE;EACE,yBAAA;AAEJ;AAAE;EACE,iBAAA;EACA,WAAA;AAEJ;AACE;EACE,gBAAA;EACA,WAAA;AACJ;AAEE;EACE,yBAAA;EACA,YAAA;AAAJ;AACI;EACE,yBAAA;EACA,YAAA;AACN;AACI;EACE,iBAAA;EACA,YAAA;AACN;AAEI;EACE,gBAAA;EACA,YAAA;AAAN;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,QAAA;AAHF;;AAQA;EACE,aAAA;EACA,mBAAA;AALF;;AAQA;EACE,iBAAA;AALF","sourcesContent":[".folderList {\r\n  flex: 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 10px;\r\n  gap: 5px;\r\n}\r\n\r\n.folder-wrapper {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 5px;\r\n}\r\n\r\n.folder {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 8px;\r\n  background-color: #f4f4f4;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n  transition: background-color 0.5s ease-in-out;\r\n  justify-content: space-between;\r\n  &:hover {\r\n    background-color: #e0e8fd;\r\n  }\r\n  svg {\r\n    margin-right: 8px;\r\n    color: #333;\r\n  }\r\n\r\n  .MuiTypography-root {\r\n    font-weight: 500;\r\n    color: #333;\r\n  }\r\n\r\n  &.selected {\r\n    background-color: #1975d1;\r\n    color: white;\r\n    &:hover {\r\n      background-color: #1975d1;\r\n      color: white;\r\n    }\r\n    svg {\r\n      margin-right: 8px;\r\n      color: white;\r\n    }\r\n\r\n    .MuiTypography-root {\r\n      font-weight: 500;\r\n      color: white;\r\n    }\r\n  }\r\n\r\n}\r\n\r\n.MuiCollapse-wrapperInner{\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-left: 32px;\r\n  gap: 5px;\r\n\r\n}\r\n\r\n\r\n.cardContent {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.expandIcon {\r\n  margin-left: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
