import React, {createContext, useContext, useEffect, useMemo, useRef} from "react";
import axios from "axios";

interface ApiContextProps {
    get: (url: string) => Promise<any>;
    post: (url: string, data?: object, ) => Promise<any>;
    put: (url: string, data?: object) => Promise<any>;
    delete: (url: string, data?: object) => Promise<any>;
    newTab: (url: string) => void;
    status: (url: string) => Promise<"online" | "offline" | "undetermined">;
    checkUrl: (url: string) => string;
    logout: () => void;
    updateLogoutFunction: (func: () => void) => void;
}

const ApiContext = createContext<ApiContextProps | undefined>(undefined);

export const useApi = (): ApiContextProps => {
    const context = useContext(ApiContext);
    if (!context) {
        throw new Error("useApi must be used within an ApiProvider");
    }
    return context;
};

export const ApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const logoutFunction = useRef<() => void>(() => {});
    const updateLogoutFunction = (func: () => void) => { logoutFunction.current = func; };

    const apiClient = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL || "/v1",  // Ensure the base URL is set on the instance
        maxRedirects: 0,  // Disable automatic redirect handling
        withCredentials: true, // If using authentication
    });

    const request = (method: string, url: string, body?: any, configs?: {contentType: "application/json" | "plain/text"}) => {
        const config: any = {
            method: method,
            url: url,  // Only set URL here, the baseURL is handled by the axios instance
            headers: {
                "Content-Type": configs?.contentType ?? "application/json",
            },
            withCredentials: true,
        };

        // Retrieve tokens from localStorage
        const sessionToken = localStorage.getItem("X-Session-Token");
        const bearerToken = localStorage.getItem("token");

        // Add the session token and bearer token if present
        if (sessionToken) {
            config.headers["X-Session-Token"] = sessionToken;
        }
        if (bearerToken) {
            config.headers["Authorization"] = `Bearer ${bearerToken}`;
        }

        // For methods other than GET, add the body as 'data'
        if (body && method !== "GET") {
            config.data = body;
        }

        // Perform the API request
        return apiClient.request(config);
    };

// Intercept responses to store session token if present
    apiClient.interceptors.response.use(
        async (response) => {
            // Handle session token and response handling
            const sessionToken = response.headers["x-session-token"];
            if (sessionToken) {
                localStorage.setItem("X-Session-Token", sessionToken);
            }

            return response;
        },
        async (error) => {
            //logoutFunction
            if (error.response) {
                switch(error.response.status) {
                    case 303:
                        const deviceId = localStorage.getItem("device-id");
                        if (!deviceId) {
                            return Promise.reject(new Error("No device-id found"));
                        }
                        try {
                            const challengeResponse = await request("POST", "/device/challenge-response", deviceId);
                            if (challengeResponse.status === 202) {
                                return apiClient.request(error.response.config);
                            } else {
                                return Promise.reject(new Error("Challenge failed"));
                            }
                        } catch (error) {
                            return Promise.reject(error);
                        }
                    case 403:
                        const requestUrl = error.response.config?.url || "";
                        console.info(`403 -> ${requestUrl}`);
                        if (!requestUrl.endsWith("logout")) {
                            console.info("403 -> Logging out...")
                            if (!!logoutFunction?.current) {
                                logoutFunction.current();
                            }
                        }
                        break;
                }
            }
            return Promise.reject(error);
        }
    );
    const checkUrl = (url: string) => {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'https://' + url; // Default to HTTPS for security
        }
        return url;
    }
    const openBookmark = (url: string) => {
        const newWindow = window.open(checkUrl(url), '_blank', 'noopener, noreferrer')
        if (newWindow) newWindow.opener = null
    }
    const checkBookmark = async (url: string) => {
        const getStatus = (status: number) => Math.floor(status / 100) !== 5;
        try {
            return fetch(checkUrl(url), { method: "HEAD" }).then(
                (response) => {
                    return getStatus(response.status) ? "online" : "offline";
                }
            );
        } catch (error) {
            // Check if the error is a CORS issue
            if (error instanceof TypeError) {
                console.warn(`Potential CORS issue detected for ${url}`);
                return "undetermined";
            }

            console.error(`Error checking status for ${url}:`, error);
            return  "offline"; // Only return false for confirmed failures
        }
    };

    const apiMethods = {
        get: (url: string) => request("GET", url),
        post: (url: string, data = {}) => request("POST", url, data),
        put: (url: string, data = {}) => request("PUT", url, data),
        delete: (url: string, data = {}) => request("DELETE", url, data),
        newTab: (url: string) => openBookmark(url),
        status: (url: string) => checkBookmark(url),
        checkUrl: (url: string) => checkUrl(url),
        logout: () => logoutFunction?.current(),
        updateLogoutFunction
    };
    return <ApiContext.Provider value={apiMethods}>
        <>
        {children}
        </>
    </ApiContext.Provider>;
};
