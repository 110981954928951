import "../style/new-domain-modal.scss";

import WidgetContainer from "../../../../components/widget/container/WidgetContainer";
import {useCallback, useState} from "react";

interface NewDomainModalProps {
    onClose: (created: boolean, entry?: string) => void;
}


const NewDomainModal: React.FC<NewDomainModalProps> = ({
    onClose
}) => {
    const [domain, setDomain] = useState<string>('');

    const handleDomainChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setDomain(event.target.value);
     }, []);

    const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onClose(true, domain.trim());
    }, [domain]);
    return (
        <WidgetContainer id={"NewDomainModal"} size={"LxF"}>
            <div className={"container"}>

                <h2>Add a new Domain</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        pattern={"^(?=.{1,50}$)(?![\\-\\.])([a-zA-Z0-9\\-]{1,63}\\.?)+[a-zA-Z]{2,63}$"}
                        required={true}
                        value={domain}
                        onChange={handleDomainChange}
                    />
                    <button
                        children={"Add"}
                        type={"submit"}
                    />
                    <button
                        className={"close-button"}
                        children={"Cancel"}
                        onClick={()=>onClose(false)}
                    />
                </form>
            </div>
        </WidgetContainer>
    )
}

export default NewDomainModal;