import  React, {createContext, useContext, useState, useEffect, ReactNode, JSX} from "react";
import {useApi} from "./ApiContext";
import {useNavigate} from "react-router-dom";

interface User {
    username: string;
    roles: string[];
}

interface UserContextProps {
    login: (userData: {username: string, password: string, deviceId: string}) => Promise<"logged In" | "login Failed">;
    logout: () => void;
    keepAlive: () => void;
    user: User | null;
}

interface UserProviderProps {
    children: ReactNode;
}

// Create context with a default value of undefined
const UserContext = createContext<UserContextProps | undefined>(undefined);

// Custom hook to use the UserContext
const useUser = (): UserContextProps => {
    const context = useContext(UserContext);

    if (!context) {
        throw new Error("useUser must be called from within the UserProvider");
    }

    return context;
}

const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
    const api = useApi();
    const navigate = useNavigate(); // Use the useNavigate hook
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        api.updateLogoutFunction(logout);
        const storedUser = localStorage.getItem("user");
        const storedRoles = localStorage.getItem("roles");
        let details: User = {username: "", roles: []};
        if (storedUser) {
            details['username'] = storedUser;
            if (storedRoles) { details['roles'] = storedRoles.split(",");        }
            setUser(details);
        }
    }, []);

    const login = (userData: {username: string, password: string, deviceId: string}): Promise<"logged In" | "login Failed"> => {
            return api.post("/auth/login", userData).then(
                (res) => {
                    if(res.status === 200){
                        console.info(res);
                        localStorage.setItem("token", res.data.token);
                        localStorage.setItem("user", userData.username);
                        localStorage.setItem("roles", res.data.roles.join(","));
                        setUser({username: userData.username, roles: res.data.roles});
                        navigate("/");
                        return "logged In";
                    }else {
                        return "login Failed";
                    }
                }
            ).catch(
                (err) => {
                    console.error(err);
                    return "login Failed";
                }
            )
    };

    const logout = async () => {
        try {
            await api.get("/auth/logout");
        } catch (err) {
            console.error("Failed to Logout....")
        }
        localStorage.removeItem("X-Session-Token");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("roles");
        localStorage.removeItem("device-id");
        setUser(null);
        navigate("/");
    };

    const keepAlive = () => {
        api.get("/session/keep-alive");
    }

    return (
        <UserContext.Provider value={{ user, login, logout, keepAlive }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
export { useUser };
