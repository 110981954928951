// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#search-bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-around;
  align-items: center;
  height: max-content;
}
#search-bar #search-elements {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
#search-bar #search-elements > * {
  padding: 1em 1.5em;
  border-radius: 5em;
  margin: 0;
}
#search-bar #search-elements .searchbar {
  flex: 1 1;
}
#search-bar #search-elements .searchbar-button {
  background: #282c34;
  color: #c4c4c4;
}
#search-bar #search-elements .searchbar-button:hover {
  background: rgb(62.1739130435, 68.3913043478, 80.8260869565);
}
#search-bar #search-elements .searchbar-button:disabled {
  background: rgb(62.1739130435, 68.3913043478, 80.8260869565);
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/components/search-bar/style/search-bar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,6BAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AACI;EACE,kBAAA;EACA,kBAAA;EACA,SAAA;AACN;AACI;EACE,SAAA;AACN;AACI;EAEE,mBADQ;EAER,cAAA;AAAN;AACM;EACE,4DAAA;AACR;AACM;EACE,4DAAA;EACA,WAAA;AACR","sourcesContent":["#search-bar {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 10px;\r\n  justify-content: space-around;\r\n  align-items: center;\r\n  height: max-content;\r\n\r\n  #search-elements {\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 10px;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n\r\n    > * {\r\n      padding: 1em 1.5em;\r\n      border-radius: 5em;\r\n      margin: 0;\r\n    }\r\n    .searchbar {\r\n      flex: 1;\r\n    }\r\n    .searchbar-button {\r\n      $color: #282c34;\r\n      background: $color;\r\n      color: #c4c4c4;\r\n      &:hover {\r\n        background: lighten($color, 10);\r\n      }\r\n      &:disabled {\r\n        background: lighten($color, 10);\r\n        color: gray;\r\n      }\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
