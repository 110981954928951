import React, {useEffect, useRef, useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    DialogActions, Autocomplete, InputAdornment, Paper, ListItemText, ListItem, List, Box, Checkbox
} from "@mui/material";
import {useApi} from "../context/ApiContext";
import {Bookmark} from "../pages/BookmarksPage";
import * as url from "url";
import {Image} from "@mui/icons-material";
import DeleteBookmarkModal from "./DeleteBookmarkModal";

interface AddBookmarkModalProps {
    bookmark?: Bookmark;
    open: boolean;
    onClose: () => void;
    onCreate: () => void;
    existingFolders: string[];
}

interface BookmarkForm {
    title: string;
    url: string;
    imageUrl: string;
    folder: string;
    monitoringEnabled: boolean;
    monitoringUrl: string;
}

const AddBookmarkModal: React.FC<AddBookmarkModalProps> = ({ open, onClose, onCreate, existingFolders, bookmark }) => {
    const api = useApi();
    const [showDelete, updateDelete] = useState<boolean>(false);
    const [form, setForm] = useState<BookmarkForm>({
        title: "",
        url: "",
        imageUrl: "",
        folder: "/user",
        monitoringEnabled: false,
        monitoringUrl: ""
    });

    useEffect(() => {
        setForm({
            title: bookmark?.title || "",
            url: bookmark?.url || "",
            imageUrl: bookmark?.imageUrl || "",
            folder: bookmark?.folder || "/user",
            monitoringEnabled: bookmark?.monitoringEnabled || false,
            monitoringUrl: bookmark?.monitoringUrl || ""
        });
    }, [bookmark]);

    const [isFolderActive, setFolderState] = useState<boolean>(false);
    const [filteredFolders, setFilteredFolders] = useState<string[]>([]);
    const formReset = () => {
        setForm({ title: "", url: "", imageUrl: "", folder: "/user", monitoringEnabled: false, monitoringUrl: "" });
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setForm(prev => ({ ...prev, [name as string]: value }));
    };

    const handleSubmit = () => {
        const newBookmark: Bookmark = {
            id: bookmark?.id || "new",
            title: form.title,
            url: form.url,
            imageUrl: form.imageUrl,
            folder: form.folder,
            monitoringStatus: bookmark?.monitoringStatus || "active",
            monitoringEnabled: form.monitoringEnabled,
            monitoringUrl: form.monitoringUrl,
            updatedAt: new Date().toISOString()
        };
        if(bookmark) {
            api.put("/bookmarks", newBookmark)
                .then((res) => {
                    formReset();
                    onCreate();
                })
                .catch((err) => {
                    console.error("Error updating bookmark", err);
                });
        }else {

            api.post("/bookmarks", newBookmark)
                .then((res) => {
                    formReset();
                    onCreate();
                })
                .catch((err) => {
                    console.error("Error adding bookmark", err);
                });
        }
    };
    const handleDeleteBookmark = () => {
        api.delete(`/bookmarks`, {id: bookmark?.id})
            .then((res) => {
                updateDelete(false);
                formReset();
                onCreate();
            })
            .catch((err) => {
                updateDelete(false);
            });
    };


    const handleClose = () => {
        onClose(); formReset();
    }

    const handleFolderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if(!value.toLowerCase().startsWith("/user")){
            if(value.length === 0) {
                value = "/user";
            }else if(value.length <= 5) {
                value = "/user";
            }
        }else if(value.length > 5 && !value.toLowerCase().startsWith("/user/")) {
            value = value.replace("/user", "/user/");
        }

        setForm(prev => ({ ...prev, folder: value }));

        // Filter the folder names based on the input
        const filtered = existingFolders.filter(folder =>
            folder.toLowerCase().startsWith(value.toLowerCase())
        );
        setFilteredFolders(filtered);
    };



    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>{!!bookmark ? "Edit" : "Add New" } Bookmark</DialogTitle>
                <DialogContent>
                    <TextField fullWidth margin="dense" label="Title" name="title" value={form.title} onChange={handleChange} required />
                    <TextField fullWidth margin="dense" label="URL" name="url" value={form.url}
                               onChange={handleChange} required
                    />
                    <TextField fullWidth margin="dense" label="Image URL" name="imageUrl" value={form.imageUrl} onChange={handleChange} />
                    <FormControl
                        fullWidth
                        margin="dense"
                        onMouseOver={() => {setFolderState(true)}}
                        onMouseLeave={() => {setFolderState(false)}}>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Folder"
                            name="folder"
                            value={form.folder}
                            autoComplete="off" // Turn off browser autocomplete
                            onChange={handleFolderChange}
                        />
                        {isFolderActive && filteredFolders.length > 0 && form.folder && (
                            <Paper style={{ marginTop: 8, maxHeight: 200, overflowY: "auto" }}>
                                <List>
                                    {filteredFolders.map((folder, index) => (
                                        <ListItem key={index} component={"button"} onClick={() => setForm(prev => ({...prev, folder: folder }))}>
                                            <ListItemText primary={folder} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        )}
                    </FormControl>
                    <FormControl
                        fullWidth
                        margin="dense"
                        style={{display: "flex", flexDirection: "row"}}
                    >
                        <Checkbox
                            checked={form.monitoringEnabled || false}
                            value={form.monitoringEnabled ? "on" : "off"}
                            onChange={(e) => {setForm(prev => ({ ...prev, [e.target.name as string]: e.target.checked}));}}
                            name="monitoringEnabled"
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Monitoring URL"
                            name="monitoringUrl"
                            value={form.monitoringUrl}
                            onChange={handleChange}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    {!!bookmark && (
                        <Button onClick={() => updateDelete(true)}  variant="contained" color="error" >Delete</Button>
                    )}
                    <Button onClick={handleClose}  variant="contained" color="warning">Cancel</Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">Save</Button>
                </DialogActions>
            </Dialog>
            <DeleteBookmarkModal
                open={showDelete && !!bookmark}
                onClose={() => updateDelete(false)}
                onConfirm={() => handleDeleteBookmark()}
                bookmarkTitle={bookmark?.title || ""}
            />
        </>
    );
};

export default AddBookmarkModal;
