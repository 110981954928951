import React from "react";
import { Container, Typography } from "@mui/material";

const NotFound: React.FC = () => {
    return (
        <Container>
            <Typography variant="h4" color="error" align="center">
                404 - Page Not Found
            </Typography>
        </Container>
    );
};

export default NotFound;
