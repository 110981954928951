import { useState, useCallback, useMemo } from "react";
import WidgetContainer, { ContainerSize } from "../widget/container/WidgetContainer";
import "./style/search-bar.scss";

interface SearchBarProps {
    size: ContainerSize;
    search: (search: string) => void;
    searchButtonCaption?: string;
    inputPattern?: string;
    inputPatternInstruction?: string;
    className?: string;
    title?: string;
    placeholder?: string;
    useSecondButton?: boolean;
    secondButtonDisabled?: boolean;
    secondButtonCaption?: string;
    secondButtonClick?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
     size,
     search,
     searchButtonCaption = "Search",
     inputPattern,
     inputPatternInstruction,
     className,
     title,
     placeholder = "Let's find what you're looking for.",
     useSecondButton = false,
     secondButtonDisabled = false,
     secondButtonCaption = "",
     secondButtonClick = () =>  {}
 }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }, []);

    const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (searchTerm.trim()) search(searchTerm);
    }, [searchTerm, search]);

    const isSubmitDisabled = useMemo(() => searchTerm.trim() === '', [searchTerm]);

    return (
        <WidgetContainer
            id="search-bar"
            size={size}
            className={className}
        >
            {title && <h1>{title}</h1>}
            <form id="search-elements" onSubmit={handleSubmit}>
                <input
                    type="text"
                    className="searchbar"
                    pattern={inputPattern}
                    required
                    title={inputPatternInstruction}
                    placeholder={placeholder}
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <button
                    className="searchbar-button"
                    disabled={isSubmitDisabled}
                    type="submit"
                >
                    {searchButtonCaption}
                </button>
                {useSecondButton && (
                    <button
                        className="searchbar-button"
                        onClick={secondButtonClick}
                        disabled={secondButtonDisabled}
                        type="button"
                    >
                        {secondButtonCaption}
                    </button>
                )}
            </form>
        </WidgetContainer>
    );
};

export default SearchBar;
